body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr:hover {
  /* background-color:#f5f5f5; */
  box-shadow: 0px 0px 10px #009966;  
}

table tbody td[contenteditable=true]:hover {
    box-shadow: 0px 0px 10px #009966;
    -webkit-box-shadow: 0px 0px 10px #009966;
    -moz-box-shadow: 0px 0px 10px #009966;
}

.changedRow {    
    box-shadow: 0px 0px 10px #ff3366;
    -webkit-box-shadow: 0px 0px 10px #ff3366;
    -moz-box-shadow: 0px 0px 10px #ff3366;    
}

.borderless {
  border:0;outline:0;
}

.imgP {max-width: 100%;max-height: 100%;margin-left: auto;margin-right: auto;display: block;}

.highlight { background-color: #78C7C7 !important; }

            #preview{
                position:absolute;
                border:1px solid #ccc;
                background:#333;
                padding:5px;
                display:none;
                color:#fff;
                 z-index: 999;
            }

            #divLargerImage
            {
                display: none;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 2%;
                bottom: 2%;
                left: 5%;
                right: 5%;
                z-index: 99;
                
            }

            #divOverlay
            {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #CCC;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                z-index: 999;
            }        